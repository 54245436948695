import { authFetch } from "./authFetch";
import Region, { blankRegion } from "./region";
import { recoilState } from "../dataStructure";
import { getRecoil, setRecoil } from "recoil-nexus";
import { atom, selector } from "recoil";
// import bcrypt from 'bcryptjs'

export default interface User {
    user_id:        string;
    email:          string;
    password_hash:  string;
    first_name:     string;
    last_name:      string;
    hometown?:     string;
    about?:         string;
    profile_image:  string;
    regions:      Region[];
    user_status:    number;
    user_attributes: {};
}
export const blankUser:User = {user_id: "", email: "", password_hash: "", first_name: "", last_name: "", profile_image: "",regions: [], user_status: 1, user_attributes: {}};
export const fallbackImage= {uri: process.env.USER_FALLBACK_IMAGE};

const apiURL = process.env.REACT_APP_ENVIRONMENT === "dev" ? process.env.REACT_APP_API_URL : process.env.REACT_APP_API_STAGE;
export async function getUserById(email: string): Promise<any> {
    console.log(email)
    const data = await fetch(apiURL + "user/" + email, {
        method: "GET",
        mode: 'cors',
        headers: { 'Content-Type': 'application/json' }
    });
    const returnData = await data.json()
    console.log(returnData);
    return returnData

}
export async function getAllUsers(): Promise<any> {
    const data = await authFetch("user/list", {
        method: "GET",
        mode: 'cors',
        headers: { 'Content-Type': 'application/json' }
    });
    const returnData = await data
    console.log(returnData);
    return returnData

}
export async function adminLogin(email: string, password: string): Promise<any> {
    console.log(email)
    console.log("admin/login/");
   const appState = getRecoil(recoilState);
    const data = await authFetch("admin/login/", {
        method: "POST",
        mode: 'cors',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify( {email: email, password: password} )
    });
    const returnData = await data;
    console.log(returnData);
    return returnData
}

export async function putAdminUser(user:User, authToken): Promise<any> {
        try {
            const data = await authFetch("user", {
                method: "POST",
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(user)
            });
            const response = await data.json();
            return await response
        } catch(error) {
            console.log(error)
            return
        }
}
// Jude, I added this trying to get the user count on dashboard. Not working but left it here as a beginning to a user list.
export async function getUsersCount(): Promise<number> {
    try {
        const data = await authFetch("users/count", { // Update the endpoint as necessary
            method: "GET",
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                // 'Authorization': `Bearer ${authToken}`, // Uncomment and use if you have the token
            }
        });
        return data.count; 
    } catch (error) {
        console.error("Error fetching users count:", error);
        return 0;
    }
}
// Atoms...
export const usersRefreshState = atom<string>({
    key: "usersRefreshState",
    default: new Date().getTime().toString()
  });
  export const selectedUserId = atom({
    key: 'selectedUserId',
    default: '0'
  })
  export const getUsers = selector({
    key: 'getAllUsers',
    get: async ({ get }) => {
      const hook = get(usersRefreshState);
      try {
        console.info("loading users");
        const users = await getAllUsers()
  
        console.log(users)
  
        if (!users || users.length === 0) return []
        return users
      } catch (err) {
        console.info("error loading users");
        console.error(err);
        return [];
      }
    }
  });