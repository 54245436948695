import Region, { blankRegion } from "./region";
import Location, { blankLocation } from "./location"
import Tag from "./tag"
import { atom, selector } from "recoil";
import Eatery, { blankEatery } from "./eatery";
import User, { blankUser, selectedUserId } from "./user";
import Plate, { blankPlate, selectedPlateId } from "./plates";
import { authFetch } from "./authFetch";

export default interface UserPost {
  review_id: string;
  region_id: string;
  restaurant_id: string;
  plate_id: string;
  user_id: string;
  review: string;
  rating: number;
  photo_url: string;
  date_time: string;
  plate: Plate;
  eatery?: Eatery;
  user?: User;
  status: number;
  comments?: Comment[];
  likes?: string[];
}
export interface IPost extends UserPost {
    save: () => Promise<any>
}
// export class PostObject implements IPost {
//   review_id = "";
//   region_id = "";
//   restaurant_id= "";
//   plate_id = "";
//   user_id = "";
//   review = "";
//   rating = "";
//   photo_url = "";
//   date_time = "";
//   plate = blankPlate;
//   eatery = blankEatery;
//   user = blankUser;

//   constructor(review_id: string, region_id: string, restaurant_id: string) {
    
//   }

//   save = async ():Promise<any> => {
//     return await putReview(this);
//   }
// }
const defaultRegion = process.env.EXPO_PUBLIC_ENVIRONMENT === "dev" ? process.env.EXPO_PUBLIC_DEFAULT_REGION_DEV : process.env.EXPO_PUBLIC_DEFAULT_REGION_STAGE;
export const blankUserPost:UserPost = {review_id: "", region_id: `${defaultRegion}`, restaurant_id: "", plate_id: "", user_id: "", review: "", rating: 0, photo_url: "", date_time: "", plate: {...blankPlate}, status: 0};

export async function getReviewsByPlateId(plate_id: string): Promise<any> {
    const data = await authFetch("plates/reviews/" + plate_id, {
        method: "GET",
        mode: 'cors',
        headers: { 'Content-Type': 'application/json' }
    });
    const returnData = await data;
    return returnData
}

export async function getReviewsByUserId(user_id: string): Promise<any> {
  const data = await authFetch("review/users/" + user_id, {
      method: "GET",
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' }
  });
  const returnData = await data;
  return returnData ? returnData : [];

}

export async function getReviews(): Promise<any> {
    const data = await authFetch("review", {
        method: "GET",
        mode: 'cors',
        headers: { 'Content-Type': 'application/json' }
    });
    const returnData = await data;
    console.log(returnData);
    return returnData
}

export async function putReview(review:UserPost): Promise<any> {
        console.log(JSON.stringify(review));
        // delete review.plate;
        try {
            const data = await authFetch("review/create", {
                method: "POST",
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(review)
            });
            const response = await data;
            return response
        } catch(error) {
            console.log(error)
            return
        }
}

export async function updateReview(review:UserPost): Promise<any> {
  console.log(review);
  delete review.eatery;
  // delete review.user.user;
  // delete review.plate;
  try {
      const data = await authFetch("review", {
          method: "POST",
          mode: 'cors',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify(review)
      });
      const response = await data;
      console.log(response);
      return await response
  } catch(error) {
      console.log(error)
      return
  }
}

// Atoms...
export const reviewRefreshState = atom<string>({
    key: "reviewRefreshState",
    default: new Date().getTime().toString()
  });

  export const getPlateReviews = selector({
    key: 'getPlateFeed',
    get: async ({get}) => {
      const hookProfiles = get(reviewRefreshState);
      const plateId = get(selectedPlateId);
      console.info(plateId);
      try {
        console.info("loading reviews by plate");
        const plate = await getReviewsByPlateId(plateId);
        console.log(plate);
        return plate.sort((a, b) => b.date_time - a.date_time) || [];
      } catch (err) {
        console.info("error loading reviews");
        console.error(err);
        return [];
      }
    }
  });
  export const getUserReviews = selector({
    key: 'getUserFeed',
    get: async ({get}) => {
      const userId = get(selectedUserId);
      const hookProfiles = get(reviewRefreshState);
      console.info(userId);
      try {
        console.info("loading reviews by user");
        const plate = await getReviewsByUserId(userId)
        console.info("loaded reviews by user");
        console.log(plate)
        let ratingTotal = 0;
        let length = plate.length;
        // compute the rank and attack the user object to the review
        plate.forEach((review:UserPost)=> {
            ratingTotal += Number(review.rating);
            // review.user = plate.filter((user:User) => user.user_id === review.user_id)[0].user;
            console.info("edited review", review);
        });
        plate.townRank = ratingTotal/length;
        console.info("edited plate", plate);
        return plate.filter(({status}) => status === 1) || [];
      } catch (err) {
        console.info("error loading reviews");
        // console.error(err);
        return [];
      }
    }
  });
  export const getAllReviews = selector({
    key: 'getFeed',
    get: async ({get}) => {
      const hookProfiles = get(reviewRefreshState)
      try {
        console.info("loading reviews");
        const reviews = await getReviews()
        // sort the plates by id so we can filter out dupliactes
        const sortedReviews = reviews.sort();

        let plateRatings: number[] = [];
        let currentPlateId:string = "";
        let iteratorDiff:number = 0;

        // Below needs to be revisited
        // It currently refines the review resuslts
        // To give us the current view on the homescreen
        // But it is currently using the entire recordset
        [...sortedReviews].forEach((item:UserPost, i:number) => {
          if (currentPlateId !== item.plate_id) {
            currentPlateId = item.plate_id;
            plateRatings= [];
            plateRatings.push(Number(item.rating));
          } else {
            plateRatings.push(Number(item.rating));
            console.log("plateRatings");
            console.log(item.plate_id);
            console.log(item.plate ? item.plate.name : "");
            console.log(plateRatings);
            console.log(plateRatings.reduce((a, b) => a + b) / plateRatings.length);
            sortedReviews[i-iteratorDiff-1].rating = (plateRatings.reduce((a, b) => a + b) / plateRatings.length).toFixed(1).toString();
            sortedReviews.splice(i-iteratorDiff,1);
            iteratorDiff ++;
          }

        });
        console.info("sortedReviews");
        console.info(sortedReviews);
        return sortedReviews.sort(function(a:UserPost, b:UserPost) {
            return (b.rating < a.rating) ? -1 : (b.rating > a.rating) ? 1 : 0;
          }) || [];
      } catch (err) {
        console.info("error loading reviews");
        console.error(err);
        return [];
      }
    }
  });
  
  export const reviewState = atom({
    key: 'reviewState',
    default: getAllReviews
  })
